import React from "react"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import tw from "tailwind-styled-components"
import { FaRegCheckCircle } from "react-icons/fa"

export const query = graphql`
  {
    allContentfulThankYouPage {
      edges {
        node {
          heading
          data {
            description
            links {
              title
              url
            }
            subtext
          }
          seo {
            data {
              title
              description
            }
          }
          legal {
            data {
              privacy {
                title
                url
              }
              terms {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

const Subtitle = tw.div`text-white font-body mt-4`

export default function ThankYou({ data }) {
  const dataNode = data.allContentfulThankYouPage.edges[0].node

  return (
    <Layout data={dataNode}>
      <div className="flex flex-col items-center justify-center h-screen px-4">
        <div className="flex flex-col justify-center max-w-[600px] w-full">
          <div className="flex items-center justify-center">
            <FaRegCheckCircle className="w-16 h-16 text-green-500" />
          </div>
          <h1 className="mt-6 text-4xl font-bold text-center text-green-500 font-title">
            {dataNode.heading}
          </h1>
          <Subtitle className="mt-8">{dataNode.data.description}</Subtitle>
          <div className="mt-4">
            <ol className="pl-12 text-blue-400 list-decimal">
              {dataNode.data.links.map((link) => (
                <li key={link.url}>
                  <a href={link.url} className="text-blue-400 underline">
                    {link.title}
                  </a>
                </li>
              ))}
            </ol>
          </div>
          <Subtitle>{dataNode.data.subtext}</Subtitle>
        </div>
      </div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </Layout>
  )
}
